<template>
  <div class="tabbar">
    <div class="item" @click="handleRedirect('Home')">
      <img class="icon" :src="current === 'Home' ? HomeActiveIcon : HomeIcon" />
      <div :class="`label ${current === 'Home' ? 'active' : ''}`">首页</div>
    </div>
    <div class="item" @click="handleRedirect('Channel')">
      <img class="icon" :src="current === 'Channel' ?ChannelActiveIcon : ChannelIcon" />
      <div :class="`label ${current === 'Channel' ? 'active' : ''}`">渠道</div>
    </div>
    <div class="item" @click="handleRedirect('DataMonitorWeb')" v-if="false">
      <img class="icon" :src="current === 'DataMonitorWeb' ? DataActiveIcon : DataIcon" />
      <div :class="`label ${current === 'DataMonitorWeb' ? 'active' : ''}`">分析</div>
    </div>
    <div class="item" @click="showModal">
      <img class="icon add" src="../../../assets/images/tabbar/add.png" />
      <div class="label">功能</div>
    </div>
    <div class="item" @click="handleRedirect('AgroDetail')">
      <img class="icon" :src="current === 'AgroDetail' ?AgricultureActiveIcon : AgricultureIcon" />
      <div :class="`label ${current === 'AgroDetail' ? 'active' : ''}`">农服</div>
    </div>
    <div class="item" @click="handleRedirect('Message')" v-if="false" >
      <div class="divright" v-if="messageSum > 0">{{messageSum}}</div>
      <img class="icon my" :src="current === 'Message' ? MessageActiveIcon : MessageIcon" />
      <div :class="`label ${current === 'Message' ? 'active' : ''}`">消息</div>
    </div>
    <div class="item" @click="handleRedirect('MyIndex')">
      <img class="icon my" :src="current === 'MyIndex' ? MyActiveIcon : MyIcon" />
      <div :class="`label ${current === 'MyIndex' ? 'active' : ''}`">我的</div>
    </div>
    <div class="bg"></div>

    <div class="modal" v-if="isShowModal">
      <div class="content">
        <div class="nav">
          <router-link to="/agriculturalTechnology">
            <NavItem icon="njh" label="农技会" />
          </router-link>
          <router-link to="/demonstrationField">
            <NavItem icon="sft" label="示范田" />
          </router-link>
          <router-link to="/terminalOutlets">
            <NavItem icon="zdwd" label="终端网点" />
          </router-link>
          <router-link to="/bigGrowers">
            <NavItem icon="zzdh" label="种植大户" />
          </router-link>
          <router-link to="/potentialCustomers">
            <NavItem icon="qzkh" label="潜在客户" />
          </router-link>
        </div>
        <div class="button-list">
          <router-link :to="{name: 'OrderAddOrderFirst', params: {seller: 'self'}}">
            <div class="button order">下订单</div>
          </router-link>
          <router-link to="/task/publishTask">
            <div class="button task">发任务</div>
          </router-link>
        </div>
        <img class="close-icon" src="../../../assets/images/tabbar/close.png" @click="closeModal"/>
      </div>
    </div>
  </div>
</template>

<script>
import HomeIcon from '../../../assets/images/tabbar/index.png';
import HomeActiveIcon from '../../../assets/images/tabbar/index-active.png';
import ChannelIcon from '../../../assets/images/tabbar/channel.png';
import ChannelActiveIcon from '../../../assets/images/tabbar/channel-active.png';
import DataIcon from '../../../assets/images/tabbar/data.png';
import DataActiveIcon from '../../../assets/images/tabbar/data-active.png';
import MessageIcon from '../../../assets/images/tabbar/message.png';
import MessageActiveIcon from '../../../assets/images/tabbar/message-active.png';
import AgricultureIcon from '../../../assets/images/tabbar/agriculture.png';
import AgricultureActiveIcon from '../../../assets/images/tabbar/agriculture-active.png';
import MyIcon from '../../../assets/images/tabbar/user.png';
import MyActiveIcon from '../../../assets/images/tabbar/user-active.png';
import NavItem from '../../../views/home/components/navItem';
// import {fetchOrderLogisticsNoticeSum} from "@/api/order";
export default {
  components: {
    NavItem,
  },
  activated() {
    // this.fetchOrderLogisticsNoticeSum();
  },
  data() {
    return {
      HomeIcon,
      HomeActiveIcon,
      ChannelIcon,
      ChannelActiveIcon,
      AgricultureIcon,
      AgricultureActiveIcon,
      DataIcon,
      DataActiveIcon,
      MessageIcon,
      MessageActiveIcon,
      MyIcon,
      MyActiveIcon,
      isShowModal: false,
      messageSum: 0,
    }
  },
  computed: {
    current() {
      return this.$route.name;
    }
  },
  methods: {
    showModal() {
      this.isShowModal = true;
    },
    closeModal() {
      this.isShowModal = false;
    },
    handleRedirect(target) {
      if (target !== this.current) {
        this.$router.push({ name: target });
      }
    },
    /*async fetchOrderLogisticsNoticeSum() {
      try {
        let response = await fetchOrderLogisticsNoticeSum();
        if (response.status == 0) {
          this.messageSum = response.data || 0;
          if (this.messageSum > 99) {
            this.messageSum = 99;
          }
        } else {
          this.$toast(response.message);
        }
      } catch (err) {
        this.$toast(err.message);
      }

    },*/
  }
}
</script>

<style lang="scss" scoped>
.tabbar {
  height: 65px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  padding:0 30px 4px;
  justify-content: space-between;
  box-sizing: border-box;
  z-index: 100;
  .icon {
    width: 28px;
    height: 28px;
    display: block;
    &.my {
      //width: 20px;
      margin-left: 2px;
    }
    &.add {
      width: 49px;
      height: 49px;
      margin-bottom: -4px;
    }
  }
  .label {
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    color: #CECECE;
    margin-top: 1px;
    &.active {
      color: #CD001F;
    }
  }
  .bg {
    width: 100%;
    height: 55px;
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    display: flex;
    align-items: flex-end;
    .content {
      height: 240px;
      width: 100%;
      background: #fff;
      border-radius: 10px 10px 0 0;
      .nav {
        display: flex;
        align-items: center;
        padding: 14px 24px 0;
        box-sizing: border-box;
        justify-content: space-between;
      }
      .button-list {
        padding: 13px 24px 0;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        .button {
          width: 157px;
          height: 50px;
          box-sizing: border-box;
          padding-left: 23px;
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
          color: #FFFFFF;
          background-color: #CD001F;
          box-shadow: rgba(235, 235, 235, 0.62);
          border-radius: 5px;
          &.order {
            background-image: url(../../../assets/images/tabbar/order.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
          &.task {
            background-image: url(../../../assets/images/tabbar/task.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
        }
      }
      .close-icon {
        width: 12px;
        height: 12px;
        margin: 27px auto 0;
      }
    }
  }

  .divright{
    position:absolute;
    color:white;
    z-index: 99;
    font-size: 12px;
    font-weight: bold;
    background-color: #CD001F;
    width: 20px;
    height: 20px;
    line-height: 20px;
    left : 70%;
    top : 12px;
    text-align: center;
    -webkit-border-radius: 2.75em;
    border-radius: 2.75em;

  }
  
}
</style>